define("maiclein-info/router", ["exports", "maiclein-info/config/environment", "ember-router-scroll"], function (_exports, _environment, _emberRouterScroll) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  class Router extends _emberRouterScroll.default {
    constructor(...args) {
      super(...args);

      _defineProperty(this, "location", _environment.default.locationType);

      _defineProperty(this, "rootURL", _environment.default.rootURL);
    }

  }

  _exports.default = Router;
  Router.map(function () {
    this.route('writing', function () {
      this.route('an-architectural-approach-to-privacy-in-public-libraries');
      this.route('junkiy-finds-a-home');
      this.route('design-ethos');
      this.route('twitter-latest');
      this.route('pour-love');
      this.route('technology-policing-13th');
      this.route('people-of-the-why');
      this.route('symbolcent');
    });
    this.route('diagrams', function () {});
    this.route('demos', function () {
      this.route('counting', function () {
        this.route('overview');
        this.route('demo');
      });
    });
    this.route('merchandise');
    this.route('someofmyfun', function () {});
    this.route('projects', function () {
      this.route('simpledata');
      this.route('projectscream');
    });
  });
});