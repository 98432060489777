define("maiclein-info/routes/counting-demo/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class CountingDemoIndexRoute extends Ember.Route {}

  _exports.default = CountingDemoIndexRoute;
});