define("maiclein-info/helpers/neighbor-or-not", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Helper.helper(function ([kn, row, col]) {
    let isNeighbor = false;
    let concat = row.toString() + "-" + col.toString();

    if (kn[concat] != undefined) {
      isNeighbor = true;
    }

    return isNeighbor;
  });

  _exports.default = _default;
});