define("maiclein-info/templates/someofmyfun/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "DOc+USup",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"layout-padding layout-column\"],[10,\"style\",\"max-width:1240px;\"],[8],[0,\"\\n\"],[4,\"paper-content\",null,[[\"class\"],[\"layout-padding\"]],{\"statements\":[[0,\"    \"],[7,\"h1\",true],[8],[0,\"Fun\"],[9],[0,\"\\n    \"],[7,\"h2\",true],[8],[0,\"When I get inspired, I make things.\"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[9],[0,\"\\n\\n\"],[7,\"div\",true],[10,\"class\",\"layout-padding\"],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"id\",\"videocontainer\"],[8],[0,\"\\n\"],[0,\"    \"],[7,\"div\",true],[10,\"id\",\"infoi\"],[8],[0,\"\\n        \"],[7,\"img\",true],[10,\"width\",\"1240px\"],[10,\"height\",\"auto\"],[10,\"src\",\"/assets/images/HollandFlowers-turned.jpeg\"],[8],[9],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"],[9],[0,\"  \\n\\n\\n  \"],[7,\"div\",true],[10,\"class\",\"layout-padding\"],[8],[0,\"\\n    \"],[7,\"div\",true],[10,\"id\",\"videocontainer\"],[8],[0,\"\\n\"],[0,\"      \"],[7,\"div\",true],[10,\"id\",\"infoi\"],[8],[0,\"\\n\"],[0,\"          \"],[7,\"img\",true],[10,\"width\",\"1240px\"],[10,\"height\",\"auto\"],[10,\"src\",\"/assets/images/pourLove-transOnWhite.png\"],[8],[9],[0,\"\\n      \"],[9],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\" \\n \\n\\n\"],[1,[22,\"outlet\"],false]],\"hasEval\":false}",
    "meta": {
      "moduleName": "maiclein-info/templates/someofmyfun/index.hbs"
    }
  });

  _exports.default = _default;
});