define("maiclein-info/helpers/member-number", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Helper.helper(function memberNumber([kn, row, col]) {
    let memberNumber;
    let concat = row.toString() + "-" + col.toString();

    if (kn[concat] != undefined) {
      memberNumber = kn[concat];
    } //Make it look pretty and synch up the array count with human count


    return memberNumber + 1;
  });

  _exports.default = _default;
});