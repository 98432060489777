define("maiclein-info/routes/writing/design-ethos", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class WritingDesignEthosRoute extends Ember.Route {}

  _exports.default = WritingDesignEthosRoute;
});