define("maiclein-info/controllers/demos/counting/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  // export default class DemosCountingIndexController extends Controller {
  var _default = Ember.Controller.extend({
    router: Ember.inject.service(),
    currentRouteName: Ember.computed.readOnly('router.currentRouteName'),
    actions: {
      noop() {}

    }
  });

  _exports.default = _default;
});