define("maiclein-info/routes/writing/technology-policing-13th", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class WritingTechnologyPolicing13thRoute extends Ember.Route {}

  _exports.default = WritingTechnologyPolicing13thRoute;
});