define("maiclein-info/routes/writing/people-of-the-why", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class WritingPeopleOfTheWhyRoute extends Ember.Route {}

  _exports.default = WritingPeopleOfTheWhyRoute;
});