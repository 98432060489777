define("maiclein-info/templates/merchandise", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "3iflx6MQ",
    "block": "{\"symbols\":[],\"statements\":[[0,\"\\n\"],[7,\"div\",true],[10,\"class\",\"layout-padding\"],[10,\"style\",\"padding-top:40px;\"],[8],[0,\"\\n\\n\"],[4,\"paper-content\",null,null,{\"statements\":[[0,\"    \"],[7,\"h3\",true],[8],[0,\"Merchandise                  \\n\"],[4,\"paper-button\",null,[[\"href\"],[\"https://shop.spreadshirt.com/simpledata\"]],{\"statements\":[[0,\"          website\\n\"]],\"parameters\":[]},null],[0,\"    \"],[9],[0,\"\\n    \"],[7,\"div\",true],[10,\"id\",\"myShop\"],[8],[0,\"\\n      \"],[7,\"a\",true],[10,\"href\",\"https://shop.spreadshirt.com/maiclein\"],[8],[0,\"maiclein\"],[9],[0,\"\\n    \"],[9],[0,\"\\n\\n    \"],[7,\"script\",true],[8],[0,\"\\n        var spread_shop_config = {\\n            shopName: 'maiclein',\\n            locale: 'us_US',\\n            prefix: 'https://shop.spreadshirt.com',\\n            baseId: 'myShop'\\n        };\\n    \"],[9],[0,\"\\n\\n    \"],[7,\"script\",true],[10,\"src\",\"https://shop.spreadshirt.com/shopfiles/shopclient/shopclient.nocache.js\"],[10,\"type\",\"text/javascript\"],[8],[0,\"\\n    \"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"  \\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "maiclein-info/templates/merchandise.hbs"
    }
  });

  _exports.default = _default;
});