define("maiclein-info/routes/demos/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class DemosIndexRoute extends Ember.Route {}

  _exports.default = DemosIndexRoute;
});