define("maiclein-info/routes/writing/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class WritingIndexRoute extends Ember.Route {}

  _exports.default = WritingIndexRoute;
});