define("maiclein-info/routes/demos/counting/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class DemosCountingIndexRoute extends Ember.Route {}

  _exports.default = DemosCountingIndexRoute;
});