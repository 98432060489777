define("maiclein-info/templates/writing/twitter-latest", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "tiwSxqFa",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"layout-padding\"],[10,\"style\",\"max-width:1040px;\"],[8],[0,\"\\n\"],[4,\"paper-content\",null,[[\"class\"],[\"layout-padding\"]],{\"statements\":[[0,\"    \"],[7,\"h1\",true],[8],[0,\"My Personal Twitter Feed\"],[9],[0,\"\\n    \"],[7,\"h2\",true],[8],[0,\"Opinions are just opinions. Insights are better.\"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"   \\n  \"],[7,\"a\",true],[10,\"class\",\"twitter-timeline\"],[10,\"data-dnt\",\"true\"],[10,\"data-theme\",\"dark\"],[10,\"href\",\"https://twitter.com/maiclein?ref_src=twsrc%5Etfw\"],[8],[0,\"Tweets by maiclein\"],[9],[0,\" \"],[7,\"script\",true],[10,\"async\",\"\"],[10,\"src\",\"https://platform.twitter.com/widgets.js\"],[10,\"charset\",\"utf-8\"],[8],[9],[0,\"\\n\"],[9],[0,\"\\n\\n\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "maiclein-info/templates/writing/twitter-latest.hbs"
    }
  });

  _exports.default = _default;
});