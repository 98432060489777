define("maiclein-info/routes/projects/simpledata", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class ProjectsSimpledataRoute extends Ember.Route {}

  _exports.default = ProjectsSimpledataRoute;
});