define("maiclein-info/routes/demos/counting/overview", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class DemosCountingOverviewRoute extends Ember.Route {}

  _exports.default = DemosCountingOverviewRoute;
});