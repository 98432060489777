define("maiclein-info/routes/writing/an-architectural-approach-to-privacy-in-public-libraries", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class WritingAnArchitecturalApproachToPrivacyInPublicLibrariesRoute extends Ember.Route {}

  _exports.default = WritingAnArchitecturalApproachToPrivacyInPublicLibrariesRoute;
});